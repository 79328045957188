// InternetSpeedChecker.js
import React, { useEffect, useState } from 'react'

const InternetSpeedChecker = ({ children }) => {
    const [internetStatus, setInternetStatus] = useState(true)
    const [message, setMessage] = useState('')
    const [alertBackground, setAlertBackground] = useState('bg-success')
    const [alertImage, setAlertImage] = useState('')

    const checkInternetSpeed = () => {
        if (navigator.connection) {
            const connection = navigator.connection
            if (connection.effectiveType == 'slow-2g' || connection.rtt > 1000) {
                setMessage('Internet is slow')
                setAlertBackground('bg-warning')
                setInternetStatus(false)
                setAlertImage(<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-exclamation-triangle' viewBox='0 0 16 16'><path d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z' /><path d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z' /></svg>)
            } else if (!navigator.onLine) {
                setMessage('No internet')
                setAlertBackground('bg-danger')
                setInternetStatus(false)
                setAlertImage(<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-exclamation-triangle' viewBox='0 0 16 16'><path d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z' /><path d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z' /></svg>)
            }
             else if (navigator.onLine) {
                setMessage('Connected')
                setAlertBackground('bg-success')
                setAlertImage(<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-check-lg' viewBox='0 0 16 16'><path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z' /></svg>)
            }
        } else {
            setMessage('Unable to determine internet speed')
        }
    }

    useEffect(() => {
        // Check internet speed initially
        checkInternetSpeed()

        // Set up an event listener to check internet speed periodically (e.g., every minute)
        const intervalId = setInterval(checkInternetSpeed, 30000)

        // Clean up interval on component unmount
        return () => clearInterval(intervalId)
    }, [message])

    return (
        <>
            {
                internetStatus ? (
                    <>
                    </>
                ) : (
                    < div className={`alert internet-notify d-inline-flex p-2 position-fixed bottom-0 end-0 border-0 align-items-center text-white ${alertBackground} opacity-75 m-2 z-inex-50 rounded-3 z-3 opacity-75`}>
                        {alertImage}
                        &nbsp;{message}
                        <button type='button' className='position-absolute top-0 end-0' data-bs-dismiss='alert' aria-label='Close'>
                            <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary'>&times;</span>
                            <span className='visually-hidden'>&times;</span>
                        </button>
                    </div >
                )
            }

            {children}
        </>
    )
}

export default InternetSpeedChecker
