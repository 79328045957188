import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { useLocation, useSearchParams } from 'react-router-dom';

const Register = () => {

    const [auth, setAuth] = useAuth()
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [submitting, setSubmitting] = useState(false)
    const [errorMessages, setErrorMessages] = useState({})
    const [agreeTermsAndCondition, setAgreeTermsAndCondition] = useState(false)
    const [stateDropdownDisabled, setStateDropdownDisabled] = useState(true)
    const [cityDropdownDisabled, setCityDropdownDisabled] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)


    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        country: '',
        state: '',
        city: '',
        password: '',
        confirmPassword: '',
        ref: ''
    })

    const controller = new AbortController()
    const signal = controller.signal

    const navigate = useNavigate()

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrorMessages(validateValues(formData))
        setSubmitting(true)
    }

    const validateValues = (data) => {
        let forNameRegEx = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/
        let forMobileRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        let forEmailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        let errorMessages = {}

        if (data.name == '') {
            errorMessages.name = 'Name required'
        }
        else if (!data.name.match(forNameRegEx)) {
            errorMessages.name = 'Enter valid name'
        }

        if (data.country == '' || data.country == '0') {
            errorMessages.country = 'Choose country'
        }

        if (data.mobile == '') {
            errorMessages.mobile = 'Mobile required'
        }
        else if (forMobileRegEx.test(data.mobile) == false) {
            errorMessages.mobile = 'Enter valid mobile'
        }

        if (data.email == '') {
            errorMessages.email = 'Email required'
        }
        else if (!data.email.match(forEmailRegEx)) {
            errorMessages.email = 'Enter valid email'
        }

        if (data.state == '' || data.state == 0) {
            errorMessages.state = 'Select state'
        }

        if (data.city == '' || data.city == 0) {
            errorMessages.city = 'Select city'
        }

        if (data.password == '') {
            errorMessages.password = 'Password required'
        }
        else if (data.password.length < 6) {
            errorMessages.password = 'Password must be 6 characters'
        }
        else if (data.password !== data.confirmPassword) {
            errorMessages.confirmPassword = 'Password doesn\'t match'
        }

        if (agreeTermsAndCondition == false) {
            errorMessages.aggrement = 'Click \'I agree\' to continue.'
        }

        return errorMessages
    }


    const insertMember = async (id, mobile, email) => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/add-owner-as-member`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'ownerId': id,
                    'memberId': id
                })
            })


            if (response.ok) {
                const apiResponse = await response.json()
                console.log('apiResponse', apiResponse)
                if (apiResponse.success) {
                    console.log(`Success: ${apiResponse}`)
                    sendOTP(mobile, email)
                }
                else {
                    errorMessages.response = apiResponse.message
                    console.log(`Error: ${errorMessages}`)
                    // setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status : ${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to add member: ${error}`)
        }
    }

    const sendOTP = async (mobile, email) => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile: mobile, rememberMe: formData.rememberMe })
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse?.success) {
                    navigate('/otp-auth', { state: { mobile: mobile, email: email, rememberMe: formData.rememberMe, register: true } })
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to send OTP : ${error}`)
        }
    }

    const finishSubmit = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    insertMember(apiResponse?.data?.insertedId, formData?.mobile, formData?.email)
                    // navigate('/login')
                    // navigate('/registration-success', { state: formData })
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to create account: ${error}`)
        }
        finally {
            setSubmitting(false)
        }
    }

    const fetchIpInfo = async () => {
        try {

            const response = await fetch(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal
            })

            const apiResponse = await response.json()

            if (apiResponse?.error) {

            }
            else {
                setFormData((prevData) => ({
                    ...prevData,
                    country: apiResponse?.country,
                }))

                getStates(apiResponse?.country)
            }

            getCountries()
        }
        catch (error) {
            console.log(`Unable to fetch IP info: ${error}`)
        }
    }

    const getCountries = async () => {
        try {
            var errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/countries`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    setCountries(apiResponse?.data)
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to fetch countries: ${error}`)
        }
    }

    const getStates = async (country) => {
        try {
            var errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/states/${country}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {

                    if (apiResponse?.data.length > 0) {
                        setStates(apiResponse?.data)
                        setStateDropdownDisabled(false)
                    }
                    else {
                        setStateDropdownDisabled(true)
                    }
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to fetch states: ${error}`)
        }
    }

    const getCities = async (state) => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/cities/${state}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    setCities(apiResponse?.data)

                    if (apiResponse?.data.length > 0) {
                        setCityDropdownDisabled(false)
                    }
                    else {
                        setCityDropdownDisabled(true)
                    }
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to fetch cities: ${error}`)
        }
    }

    useEffect(() => {


        const queryParams = new URLSearchParams(location.search);
        const manualReference = queryParams.get('ref');

        if (manualReference) {
            setFormData((prevData) => ({
                ...prevData,
                ref: manualReference, // Set the reference value from the query param
            }));
        }

        if (countries.length == 0) {
            fetchIpInfo()
        }

        if (auth?.user?.token) {
            navigate(-1 || '/')
        }

        if (Object.keys(errorMessages).length == 0 && submitting) {
            finishSubmit()
        }
        else {
            setSubmitting(false)
        }

        return () => controller.abort()
        //eslint-disable-next-line
    }, [errorMessages])

    return (
        <>
            <div className='row g-0 app-auth-wrapper'>
                <div className='col-12 col-md-7 col-lg-6 auth-main-col text-center p-5'>
                    <div className='d-flex flex-column align-content-end'>
                        <div className='app-auth-body mx-auto'>

                            <div className='app-auth-branding mb-3'><Link className='app-logo text-decoration-none' to='/'><img className='logo-icon me-2' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' /></Link></div>
                            <h2 className='auth-heading text-center mb-4'>Rent Your World with Ease!</h2>
                            <div className='auth-form-container text-start mx-auto'>
                                <form className='auth-form auth-signup-form'>
                                    <div className='email mb-3'>
                                        <label className='sr-only'>Your Name</label>
                                        <input type='text' name='name' value={formData.name} className='form-control' placeholder='Full name' onChange={handleInputChange} />
                                        {errorMessages.name ? (<p className='form-text text-danger m-0'> {errorMessages.name}</p>) : null}
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-5 mb-3'>
                                            <label className='sr-only'>Country</label>
                                            <select name='country' className='form-select form-control w-100' value={formData.country} onChange={(e) => {
                                                handleInputChange(e)
                                                getStates(e.target.value)
                                            }}>
                                                <option value='0'>Choose country...</option>
                                                {countries?.map(country => (
                                                    <option key={country.id} value={country.iso2}>{country.phone_code} ({country.iso2})</option>
                                                ))}
                                            </select>
                                            {errorMessages.country ? (<p className='form-text text-danger m-0'> {errorMessages.country}</p>) : null}
                                        </div>

                                        <div className='col-md-7 mb-3'>
                                            <label className='sr-only'>Your Mobile</label>
                                            <input type='text' name='mobile' maxLength={10} value={formData.mobile} className='form-control' placeholder='Mobile number' onChange={handleInputChange} />
                                            {errorMessages.mobile ? (<p className='form-text text-danger m-0'> {errorMessages.mobile}</p>) : <div className='form-text'>We'll never share your mobile no.</div>}
                                        </div>
                                    </div>

                                    <div className=' mb-3'>
                                        <label className='sr-only'>Your Email</label>
                                        <input type='email' name='email' value={formData.email} className='form-control' placeholder='Email address' onChange={handleInputChange} />

                                        {errorMessages.email ? (<p className='form-text text-danger m-0'> {errorMessages.email}</p>) : <div className='form-text'>We'll never share your email with anyone else.</div>}
                                    </div>

                                    <div className='row'>


                                        <div className='col-md-6 mb-3'>
                                            <label className='sr-only'>State</label>
                                            <select name='state' className='form-select form-control w-100' value={formData.state} onChange={(e) => {
                                                handleInputChange(e)
                                                getCities(e.target.value)
                                            }} disabled={stateDropdownDisabled}>
                                                <option value='0'>Choose state...</option>
                                                {states?.map(state => (
                                                    <option key={state.id} value={state.name}>{state.name} ({state.state_code})</option>
                                                ))}
                                            </select>
                                            {errorMessages.state ? (<p className='form-text text-danger m-0'> {errorMessages.state}</p>) : null}
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className='sr-only'>City</label>

                                            <select name='city' className={`form-select form-control w-100`} value={formData.city} onChange={(e) => {
                                                handleInputChange(e)
                                            }} disabled={cityDropdownDisabled}>
                                                <option value='0'>Choose city...</option>
                                                {cities?.map(city => (
                                                    <option key={city.id} value={city.name}>{city.name} ({city.state_code})</option>
                                                ))}
                                            </select>
                                            {errorMessages.city ? (<p className='form-text text-danger m-0'> {errorMessages.city}</p>) : null}
                                        </div>
                                    </div>

                                    <div className='password mb-3'>
                                        <label className='sr-only'>Password</label>
                                        <div className='input-group'>
                                            <input type={`${showPassword ? 'text' : 'password'}`} name='password' value={formData.password} className='form-control' onChange={handleInputChange} placeholder='Create a password' />
                                            <button className='input-group-text' type='button' onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye' viewBox='0 0 16 16'>
                                                    <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                                                    <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                                                </svg> : <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye-slash' viewBox='0 0 16 16'>
                                                    <path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z' />
                                                    <path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829' />
                                                    <path d='M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z' />
                                                </svg>}
                                            </button>
                                        </div>
                                        {errorMessages.password ? (<p className='form-text text-danger m-0'> {errorMessages.password}</p>) : null}
                                        <div className='form-text'>
                                            Your password at least 6 characters long, contain letters, numbers and special characters, and must not contain spaces, or emoji.
                                        </div>
                                    </div>

                                    <div className='password mb-3'>
                                        <label className='sr-only'>Confirm Password</label>
                                        <div className='input-group'>
                                            <input type={`${showConfirmPassword ? 'text' : 'password'}`} name='confirmPassword' value={formData.confirmPassword} className='form-control form-control' onChange={handleInputChange} placeholder='Re-enter password' />
                                            <button className='input-group-text' type='button' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                {showConfirmPassword ? <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye' viewBox='0 0 16 16'>
                                                    <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                                                    <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                                                </svg> : <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye-slash' viewBox='0 0 16 16'>
                                                    <path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z' />
                                                    <path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829' />
                                                    <path d='M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z' />
                                                </svg>}
                                            </button>
                                        </div>
                                        {errorMessages.confirmPassword ? (<p className='form-text text-danger m-0'> {errorMessages.confirmPassword}</p>) : null}
                                    </div>

                                    <div className='extra mb-4'>
                                        <div className='form-check'>
                                            <div className='form-check'>
                                                <input className='form-check-input' defaultChecked={agreeTermsAndCondition} type='checkbox' onChange={() => {
                                                    setAgreeTermsAndCondition(agreeTermsAndCondition ? false : true)
                                                }} id='agreeCheck' />
                                                <label className='form-check-label' htmlFor='agreeCheck'>
                                                    I agree&nbsp;<Link to='' className='app-link'>Terms of Service</Link> and <Link to='' className='app-link'>Privacy Policy</Link>.
                                                </label>
                                            </div>
                                            {errorMessages.aggrement ? (<p className='form-text text-danger m-0'> {errorMessages.aggrement}</p>) : null}

                                        </div>
                                    </div>

                                    <div className='text-center'>
                                        <button type='submit' onClick={handleSubmit} className='btn app-btn-primary w-100 theme-btn mx-auto' disabled={submitting}>Sign Up</button>
                                    </div>
                                    {errorMessages.response ? (<p className='form-text text-danger m-0'> {errorMessages.response}</p>) : null}
                                </form>

                                <div className='auth-option text-center pt-5'>Already have an account?
                                    <Link className='text-link text-decoration-none' to='/login' >Log in</Link>
                                </div>
                            </div>

                        </div>

                        <footer className='app-auth-footer'>
                            <div className='container text-center py-3'>

                                <small className='copyright'>&copy;{new Date().getFullYear()} ScanConnect&trade;</small>

                            </div>
                        </footer>
                    </div>
                </div>
                <div className='col-12 col-md-5 col-lg-6 h-100 auth-background-col'>
                    <div className='app-signup auth-background-holder' style={{
                        backgroundImage: 'url(./Images/Background/background-2.jpg)',
                        backgroundSize: 'cover',
                        height: '100vh',
                        minHeight: '100%',
                    }}></div>
                    <div className='auth-background-mask'></div>
                    <div className='auth-background-overlay p-3 p-lg-5'>
                        <div className='d-flex flex-column align-content-end h-100'>
                            <div className='h-100'></div>
                            <div className='overlay-content p-3 p-lg-4 rounded'>
                                <h5 className='mb-3 overlay-title'>Unlock Possibilities</h5>
                                <div>Your Life, Your Way : Rent it all, effortlessly</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Register